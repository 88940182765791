<template>
  <div class="menu-body" v-if="$oidc.isAuthenticated">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="main-con">
      <div class="view-asmt">
        <b-table id="assign-plant-validation-table" class="assign-plant-validation-table gn-table-sm" responsive
          :items="items" :fields="fields">
          <template #cell(totalProposedPracticesCount)="row">
            <div class="practice-numbers">
              {{ row.item.totalProposedPracticesCount }}
            </div>
          </template>
          <template #cell(majorRequestCount)="row">
            <div class="practice-numbers">
              {{ row.item.majorRequestCount }}
            </div>
          </template>
          <template #cell(minorRequestCount)="row">
            <div class="practice-numbers">
              {{ row.item.minorRequestCount }}
            </div>
          </template>
          <template #cell(clarityRequestCount)="row">
            <div class="practice-numbers">
              {{ row.item.clarityRequestCount }}
            </div>
          </template>
          <template #cell(assignedPlants)="row">
            <div v-for="(eachRegion, i) in row.item.assignedPlants" :key="i">
              <strong>{{ eachRegion.region }}</strong> : {{ eachRegion.plants }}<br />
            </div>
            <div v-if="row.item.assignedPlants.length == 0" class="">
              Plants are not yet assigned.
            </div>
          </template>
          <template #cell(assignPlantValidationButtonStatus)="row">
            <b-button pill variant="outline-secondary" class="save-button save-btn primary-color validation-btn"
              @click="gotoAssignPlant(row.item)" :disabled="!row.item.assignPlantValidationButtonStatus">
              Assign Plant Validation
            </b-button>
          </template>
        </b-table>
        <div v-if="!items.length" class="no-data">
          <img alt="no data" src="../../../assets/No_Data.svg" />
          <span class="ed-date">No Records Found</span>
        </div>
      </div>
    </div>

    <div class="back-btn-con button-reg">
      <b-button pill class="add-action-btn" @click="back">Close</b-button>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Api from "../../../common/constants/api";
import ApiCalls from "../../../common/api/apiCalls";
import Loader from "../../../components/common/Loader.vue";
import helper from "@/common/helper";
import roles from "@/common/constants/roles";

export default {
  name: "AssignPlantValidation",
  data() {
    return {
      showLoader: false,
      roles: roles,
      fields: [
        {
          key: "pillarCode",
          label: "Pillar",
          sortable: false,
        },
        {
          key: "totalProposedPracticesCount",
          label: "Total Practices",
          sortable: false,
        },
        {
          key: "majorRequestCount",
          label: "Major",
          sortable: false,
        },
        {
          key: "minorRequestCount",
          label: "Minor ",
          sortable: false,
        },
        {
          key: "clarityRequestCount",
          label: "Clarity ",
          sortable: false,
        },
        {
          key: "assignedPlants",
          label: "Assigned Plants",
          sortable: false,
        },

        {
          key: "assignPlantValidationButtonStatus",
          label: "",
          sortable: false,
        },
      ],
      items: [],
    };
  },
  components: {
    Breadcrumb,
    Loader,
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 2,
      subMenu: 4,
    });
    this.$store.dispatch("breadcrumb", {
      title: "Assign Plant Validation ",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        {
          name: "Governance / ",
          redirect: "/governance",
          primary: true,
        },
        { name: "Assign Plant Validation ", primary: false },
      ],
    });
  },
  created() {
    if (!this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER)) {
      this.$router.push("/");
      return;
    }
    this.getTableData();
    this.getPracticeCount();
  },
  methods: {
    back() {
      this.$router.push("/governance");
    },
    gotoAssignPlant(obj) {
      console.log(obj.reqIds);
      helper.setAssignValidationReqIDs(obj.reqIds);
      this.$router.push("/assign-validation-req");
    },
    getTableData() {
      ApiCalls.httpCallDetails(Api.GETASSIGNPLANTVALIDATION, "get").then(
        (data) => {
          console.log(data);
          data.data.forEach((req) => {
            let regionList = {};
            req.assignedPlants.forEach((plantData) => {
              regionList[plantData.regionName] = {
                name: plantData.regionName,
                plants:
                  (regionList[plantData.regionName] &&
                    regionList[plantData.regionName].plants
                    ? regionList[plantData.regionName].plants
                    : "") +
                  plantData.plantName +
                  ", ",
              };
            });
            req.assignedPlants = [];
            for (const key in regionList) {
              if (Object.hasOwnProperty.call(regionList, key)) {
                const element = regionList[key];
                let plantList = element.plants.substring(
                  0,
                  element.plants.length - 2
                );
                req.assignedPlants.push({
                  region: element.name,
                  plants: plantList,
                });
              }
            }
          });
          this.items = data.data;
        }
      );
    },
    getPracticeCount() {
      ApiCalls.httpCallDetails(Api.GETREQUESTGLOBALCOUNT, "get").then(
        (data) => {
          this.fields[1].label =
            "Total Practices (" + data.data.totalProposedPracticesCount + ")";
          this.fields[2].label = "Major (" + data.data.majorRequestCount + ")";
          this.fields[3].label = "Minor (" + data.data.minorRequestCount + ")";
          this.fields[4].label =
            "Clarity (" + data.data.clarityRequestCount + ")";
        }
      );
    },
  },
};
</script>
<style scoped>
.main-con {
  padding: 0px;
}

.last-asmt-con {
  font-size: 1.083rem;
  font-family: "BarlowR", sans-serif;
}

.status-last-asmt-con {
  font-size: 1.083rem;
  font-family: "BarlowR", sans-serif;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
}

.save-button {
  background-color: #eff3ff !important;
  font-size: 1rem;
  font-family: "BarlowM", sans-serif;
  width: 5.6rem;
  height: 2.9rem;
  border-color: #869ed8;
}

.mat-info-con {
  padding: 2rem;
  display: flex;
}

.plant-dd {
  width: 20%;
}

.plant-select {
  height: 54px;
  border-color: #313131;
}

.plant-card {
  background: #fffce3;
  width: 160px;
  height: 54px;
  margin-left: 1.5em;
  text-align: center;
  color: #414141;
  padding: 5px;
  border-radius: 7px;
}

.validation-btn {
  border-color: #274ba7;
  height: 2.6rem;
  width: 12.5rem;
}

.practice-numbers {
  font-size: 1.16rem;
  font-family: "BarlowM", sans-serif;
}

.validation-btn:hover {
  color: #274ba7 !important;
  background: #274ba741 0% 0% no-repeat padding-box !important;
}

.add-action-btn {
  margin: 1rem 0;
  background: #274ba7;
  border-color: hsl(223, 62%, 40%);
  width: 5.5rem;
  padding: 0.75rem 1rem;
}

.add-action-btn:hover {
  border-color: #274ba7 !important;
  color: #fff !important;
  background: #274ba7 0% 0% no-repeat padding-box !important;
}

.add-action-btn:active {
  border-color: #274ba7 !important;
  color: #fff !important;
  background: #274ba7 0% 0% no-repeat padding-box !important;
}

.add-action-btn:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.back-btn-con {
  /* padding-right: 2rem; */
  text-align: right;
}

.no-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  height: 8rem;
  justify-content: space-evenly;
}

.last-asmt-date {
  font-family: "BarlowM", sans-serif;
}

.status-icons {
  margin: 0px 5px;
  width: 1.66rem;
  height: 1.66rem;
}

.status-name {
  margin-right: 1rem;
  font: 1.08rem "BarlowM", sans-serif;
  color: #313131;
}

@media only screen and (max-width: 767px) {
  .mat-info-con[data-v-dcabda2e] {
    display: block !important;
  }

  .plant-dd[data-v-dcabda2e] {
    width: 100% !important;
  }

  .plant-card[data-v-dcabda2e] {
    width: 100% !important;
    margin-left: 0em !important;
    margin-top: 1em;
  }
}
</style>